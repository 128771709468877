import { render, staticRenderFns } from "./setup.vue?vue&type=template&id=62640e22&scoped=true&"
import script from "./setup.vue?vue&type=script&lang=js&"
export * from "./setup.vue?vue&type=script&lang=js&"
import style0 from "./setup.vue?vue&type=style&index=0&id=62640e22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62640e22",
  null
  
)

export default component.exports